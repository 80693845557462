import React from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import transliterationData from "../../content/transliterationData"

const TransliterationTable = () => {
  return (
    <>
      <TableContainer style={{ maxHeight: "60vh" }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell align="right">
                Urdu Alphabet
              </TableCell>
              <TableCell style={{ maxWidth: 200 }}>
                Transliteration in Roman Script
              </TableCell>
              <TableCell>
                Example
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transliterationData.map(({ urdu, roman, romanExtended, example }, index) => (
              <TableRow key={urdu} style={{ backgroundColor: index % 2 ? "rgba(0, 0, 0, 0.04)" : null }}>
                <TableCell align="right">
                  <b>{urdu}</b>
                </TableCell>
                <TableCell style={{ maxWidth: 200 }}>
                  {romanExtended ? roman : <b>{roman}</b>}
                </TableCell>
                <TableCell>
                  {example}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ol>
        <li style={{ fontStyle: "italic", lineHeight: 1.5, paddingBottom: 8 }}>
          For long vowel sounds: for long vowel sounds, double letters of a, u, e will be used.<br />For example: duur = دُور
        </li>
        <li style={{ fontStyle: "italic", lineHeight: 1.5, paddingBottom: 8 }}>
          For the diacritics or short vowel sounds, i.e. zer, zabr, and pesh, i, a, and u will be used.<br />For example: dur = دُر
        </li>
      </ol>
    </>
  )
}

export default TransliterationTable