import {
  Box,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import SearchIcon from "@material-ui/icons/Search"
import ClearAllIcon from "@material-ui/icons/ClearAll"
import { Autocomplete } from "@material-ui/lab"
import React, { useState } from "react"
import {
  connectRefinementList,
  connectCurrentRefinements,
} from "react-instantsearch-dom"

const AccNoBox = connectRefinementList(({ title, refine }) => {
  const [input, setInput] = useState("")
  return (
    <Box display="flex" py={1}>
      <TextField
        label={title}
        value={input}
        onChange={e => setInput(e.target.value)}
        size="small"
        InputProps={
          input.length
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setInput("")
                        refine("")
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : null
        }
      />
      <IconButton
        variant="filled"
        edge="end"
        onClick={() => refine(input)}
        disabled={!input.length}
      >
        <SearchIcon />
      </IconButton>
    </Box>
  )
})

const Filter = connectRefinementList(
  ({ items, searchForItems, refine, title, type }) => {
    return (
      <>
        <Box py={1}>
          <Autocomplete
            defaultValue={type ? [{ label: type }] : []}
            multiple
            size="small"
            // includeInputInList
            options={items}
            getOptionLabel={item => item.label}
            getOptionSelected={(option, value) => option.label === value}
            renderInput={params => (
              <TextField {...params} label={title} variant="outlined" />
            )}
            renderOption={item => (
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography>{item.label}</Typography>
                <Chip label={item.count} size="small" color="primary" />
              </Box>
            )}
            onInputChange={(e, value) => {
              searchForItems(value)
            }}
            onChange={(e, value) => {
              const valueArray = value.map(item => item.label)
              refine(valueArray)
            }}
          />
        </Box>
      </>
    )
  }
)

const ClearRefinements = connectCurrentRefinements(({ items, refine }) => (
  <Box textAlign="right">
    <Button
      onClick={() => refine(items)}
      disabled={!items.length}
      endIcon={<ClearAllIcon />}
      size="small"
    >
      Clear filters
    </Button>
  </Box>
))

const SearchFilters = ({ type }) => {
  return (
    <Box>
      <ClearRefinements />
      <Filter title="Author" attribute="AUTHOR" limit={5} searchable />
      <Filter title="Title" attribute="TITLE" limit={5} searchable />
      <Filter
        title="Type"
        attribute="TYPE"
        defaultRefinement={type}
        type={type}
      />
      <Filter title="Publisher" attribute="Publisher" limit={5} searchable />
      <Filter title="Country" attribute="COUNTRY" limit={5} searchable />
      <Filter title="Language" attribute="Language.1" limit={5} searchable />
      <Filter title="Year" attribute="YEAR.START" limit={5} searchable />
      <AccNoBox title="Acc No" attribute="ACCNO" limit={3} />
    </Box>
  )
}

export default SearchFilters
