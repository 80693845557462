import {
  Container,
  Box,
  Typography,
  Hidden
} from "@material-ui/core"
import React from "react"
import Layout from "../components/layout"
import Search from "../components/search"
import TranslitButton from "../components/search/translitButton"
import Seo from "../components/seo"
const searchIndices = [
  { name: `sarrc_catalog`, title: `TITLE` },
]
const suggestIndex = "sarrc_catalog_suggestions"

const SearchPage = ({location}) => {
  return (
    <Layout>
      <Seo title="Catalogue" />
      <Container
        /* maxWidth="md" */ style={{ paddingTop: 80, paddingBottom: 80 }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" color="primary">
            Search the archive
          </Typography>
          <Hidden smDown>
            <TranslitButton />
          </Hidden>
        </Box>
        <Search
          indices={searchIndices}
          suggestIndex={suggestIndex}
          searchString={location.search}
          type={location?.state?.type}
        />
      </Container>
    </Layout>
  )
}

export default SearchPage