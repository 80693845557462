import {
  Box,
  Typography,
  CardContent,
  Card,
  TextField,
  MenuItem,
  CircularProgress,
} from "@material-ui/core"
import { Button, IconButton } from "gatsby-theme-material-ui"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import FirstPageIcon from "@material-ui/icons/FirstPage"
import LastPageIcon from "@material-ui/icons/LastPage"
import React, { useEffect } from "react"
import {
  connectStateResults,
  connectPagination,
  Index,
  connectHitsPerPage,
} from "react-instantsearch-dom"
import ResultItem from "./resultItem"

const HitCount = connectStateResults(({ searchResults, isSearchStalled }) => {
  const hitCount = searchResults && searchResults.nbHits

  return (
    <div className="HitCount">
      {isSearchStalled ? null : (
        <Typography>
          {hitCount} result{hitCount !== 1 ? `s` : ``}
        </Typography>
      )}
    </div>
  )
})

const CustomHits = connectStateResults(({ searchResults, isSearchStalled, query }) => {
  return searchResults ? (
    searchResults?.hits.length <= 0 ? (
      <Card>
        <CardContent>
          Your query did not return any results. Try clearing filters or using broader search terms
        </CardContent>
      </Card>
    ) : (
      searchResults?.hits.map((hit, index) => (
        <ResultItem key={index} hit={hit} query={query} />
      ))
    )
  ) : (
    <Card>
      <CardContent style={{ textAlign: "center" }}>
        <CircularProgress />
      </CardContent>
    </Card>
  )
})

const CustomPagination = connectPagination(
  ({ currentRefinement, nbPages, refine, padding, query }) => {
    useEffect(() => {
      refine(1)
    }, [query])
    return (
      <Box component="ul" textAlign="center" style={{ paddingInlineStart: 0 }}>
        <IconButton
          disabled={currentRefinement === 1}
          onClick={() => refine(1)}
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={() => refine(currentRefinement - 1)}
          disabled={currentRefinement <= 1}
        >
          <NavigateBeforeIcon />
        </IconButton>
        {new Array(nbPages).fill(null).map((_, index) => {
          const page = index + 1
          const showNum = () => {
            if (currentRefinement <= padding + 1) {
              if (page <= padding * 2 + 1) {
                return true
              }
            } else if (
              page <= currentRefinement + padding &&
              page >= currentRefinement - padding
            ) {
              return true
            }
          }

          if (showNum()) {
            return (
              <Box
                component="li"
                key={index}
                style={{ display: "inline" }}
                px={0.5}
                mx={1}
              >
                <Button
                  size="small"
                  variant={
                    index + 1 === currentRefinement ? "contained" : "text"
                  }
                  onClick={() => refine(page)}
                  style={{ minWidth: 32, borderRadius: 16 }}
                >
                  {page}
                </Button>
              </Box>
            )
          } else return null
        })}
        <IconButton
          onClick={() => refine(currentRefinement + 1)}
          disabled={currentRefinement >= nbPages}
        >
          <NavigateNextIcon />
        </IconButton>
        <IconButton
          onClick={() => refine(nbPages)}
          disabled={currentRefinement >= nbPages}
        >
          <LastPageIcon />
        </IconButton>
      </Box>
    )}
)

const CustomHitsPerPage = connectHitsPerPage(
  ({ items, currentRefinement, refine }) => (
    <TextField
      select
      size="small"
      value={currentRefinement}
      onChange={event => refine(event.target.value)}
    >
      {items.map(item => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  )
)

const ResultsMeta = () => (
  <Box display="flex" justifyContent="space-between">
    <HitCount />
    <Box display="flex">
      <Typography style={{ paddingRight: 8 }}>Results per page: </Typography>
      <CustomHitsPerPage
        defaultRefinement={10}
        items={[
          { value: 10, label: "10" },
          { value: 25, label: "25" },
          { value: 50, label: "50" },
          { value: 100, label: "100" },
        ]}
      />
    </Box>
  </Box>
)

const HitsInIndex = ({ index, query }) => (
  <Index indexName={index.name}>
    <ResultsMeta />
    <Box display={{ xs: "none", md: "block" }}>
      <CustomPagination padding={4} query={query} />
    </Box>
    <Box display={{ xs: "block", md: "none" }}>
      <CustomPagination padding={0} query={query} />
    </Box>
    <CustomHits className="Hits" query={query} />
    <Box display={{ xs: "none", md: "block" }}>
      <CustomPagination padding={4} />
    </Box>
    <Box display={{ xs: "block", md: "none" }}>
      <CustomPagination padding={0} />
    </Box>
    <ResultsMeta />
  </Index>
)

const SearchResult = ({ indices, className, show, query }) =>
  show ? (
    <div className={className}>
      {indices.map(index => (
        <HitsInIndex index={index} key={index.name} query={query} />
      ))}
    </div>
  ) : <Card><CardContent>No query</CardContent></Card>

export default SearchResult
