import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core"
import TranslateIcon from "@material-ui/icons/Translate"
import React, { useState } from "react"
import TransliterationTable from "../transliterationTable"

const TranslitGuide = () => {
  const [dialogOpen, setDialogOpen] = useState(false)
  return (
    <div>
      <Button
        variant="outlined"
        onClick={() => setDialogOpen(true)}
        endIcon={<TranslateIcon />}
      >
        Transliteration
      </Button>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Transliteration Guide</DialogTitle>
        <DialogContent>
          <TransliterationTable />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default TranslitGuide