import { Box, Button, TextField } from "@material-ui/core"
import React from "react"
import { connectAutoComplete } from "react-instantsearch-dom"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { navigate } from "gatsby"


const SearchBox = ({ hits, refine, query, urlParams, currentRefinement}) => {
  return (
    <Box display="flex" width="100%">
      <Autocomplete
        id="combo-box"
        freeSolo
        value={query}
        onInputChange={(event, value) => refine(value)}
        onChange={(event, value, reason) => {
          if (reason === "clear") {
            navigate(`/search`)
          } else
            urlParams.delete("pg")
            urlParams.set("query", value)
            navigate(`/search?${urlParams.toString()}`)
          }
        }
        options={hits.map(option => option.query)}
        fullWidth
        renderInput={params => (
          <TextField
            id="search-input"
            {...params}
            placeholder="Search the archive"
            InputProps={{ ...params.InputProps, type: "search" }}
          />
        )}
      />
      <Button
        onClick={() =>
          navigate(`/search?query=${encodeURIComponent(currentRefinement)}`)
        }
        disabled={!currentRefinement}
      >
        Search
      </Button>
    </Box>
  )
}

export default connectAutoComplete(SearchBox)