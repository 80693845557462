import React from "react"

const transliterationData = [
  {
    urdu: "آ",
    roman: <><b>a</b> when appearing at the beginning of a word, for the elongated ‘aa’ vowel sound<sup>1</sup></>,
    romanExtended: true,
    example: "Am = آم",
  },
  {
    urdu: "ا",
    roman: <><b>a</b> (when appearing within a word)</>,
    romanExtended: true,
    example: "Kaam = کام",
  },
  {
    urdu: "اَ",
    roman: <><b>a</b> when appearing as the first letter with the vowel sound of a short ‘a’, or ‘uh’<sup>2</sup></>,
    romanExtended: true,
    example: "Andar = اَندر",
  },
  {
    urdu: "اِ",
    roman: "i",
    example: "Insan = اِنسان",
  },
  {
    urdu: "اُ",
    roman: "u",
    example: "Ulat = اُلٹ",
  },
  {
    urdu: "ب",
    roman: "b",
    example: "Baas = باس",
  },
  {
    urdu: "پ",
    roman: "p",
    example: "Pardah = پردہ",
  },
  {
    urdu: "ت",
    roman: "t",
    example: "Tum = تُم",
  },
  {
    urdu: "ت",
    roman: "t",
    example: "Tum = تُم",
  },
  {
    urdu: "ٹ",
    roman: "t",
    example: "Tamatar = ٹماٹر",
  },
  {
    urdu: "ث",
    roman: "s",
    example: "Usman = عُثمان",
  },
  {
    urdu: "ج",
    roman: "j",
    example: "Jalebi = جَلیبی",
  },
  {
    urdu: "چ",
    roman: "ch",
    example: "Chimta = چِمٹا",
  },
  {
    urdu: "ح",
    roman: "h",
    example: "Hairaan = حیران",
  },
  {
    urdu: "خ",
    roman: "kh",
    example: "Khitaab = خِطاب",
  },
  {
    urdu: "د",
    roman: "d",
    example: "Dard = دَرد",
  },
  {
    urdu: "ڈ",
    roman: "d",
    example: "Anda = اَنڈا",
  },
  {
    urdu: "ذ",
    roman: "z",
    example: "Zikr = زِکر",
  },
  {
    urdu: "ر",
    roman: "r",
    example: "Rang = رَنگ",
  },
  {
    urdu: "ڑ",
    roman: "r",
    example: "Ragar = رَگڑ",
  },
  {
    urdu: "ز",
    roman: "z",
    example: "Zid = زِد",
  },
  {
    urdu: "س",
    roman: "s",
    example: "Saans = سانس",
  },
  {
    urdu: "ش",
    roman: "sh",
    example: "Taash = تاش",
  },
  {
    urdu: "ص",
    roman: "s",
    example: "Subah = صُبح",
  },
  {
    urdu: "ض",
    roman: "z",
    example: "Raaz = راض",
  },
  {
    urdu: "ط",
    roman: "t",
    example: "Tota = طوطا",
  },
  {
    urdu: "ظ",
    roman: "z",
    example: "Manzar = مَنظر",
  },
  {
    urdu: "ع",
    roman: <><b>e</b> or <b>ai</b> when appearing as the first letter, <b>aa</b> when appearing within a word</>,
    romanExtended: true,
    example: <>Baad = بعد<br />Ainak/ Enak = عینک</>,
  },
  {
    urdu: "غ",
    roman: "g",
    example: "Maghrib = مغرب",
  },
  {
    urdu: "ف",
    roman: "f",
    example: "Saaf = صاف",
  },
  {
    urdu: "ق",
    roman: "q",
    example: "Qainchi = قینچی",
  },
  {
    urdu: "ک",
    roman: "k",
    example: "Kam = کَم",
  },
  {
    urdu: "گ",
    roman: "g",
    example: "Gol = گول",
  },
  {
    urdu: "ل",
    roman: "l",
    example: "Lal = لال",
  },
  {
    urdu: "م",
    roman: "m",
    example: "Man = مَن",
  },
  {
    urdu: "ن",
    roman: "n",
    example: "Daant = دانت",
  },
  {
    urdu: "ں",
    roman: "n",
    example: "Rawan = رَواں",
  },
  {
    urdu: "و",
    roman: <><b>v</b> or <b>w</b> when appearing as the first letter, <b>o</b> or <b>u</b> when appearing within a word (depending on the vowel sound)</>,
    romanExtended: true,
    example: <>Waqt/ Vaqt = وقت<br />Dost = دوست<br />Duur = دُور</>,
  },
  {
    urdu: "ہ",
    roman: "h",
    example: "Siyah = سیاہ",
  },
  {
    urdu: "ی",
    roman: <><b>i</b> or <b>y</b> when appearing at the end of a word, <b>ee</b> or <b>y</b> when appearing within a word</>,
    romanExtended: true,
    example: <>Judai = جُدائ<br />Jadeed = جَدید</>,
  },
  {
    urdu: "ے",
    roman: "ay",
    example: "Baray = بڑَے",
  },
]

export default transliterationData