import { FormControlLabel, Grid, Switch, Grow, Box, Hidden } from "@material-ui/core"
import algoliasearch from "algoliasearch/lite"
import { createRef, default as React, useState, useMemo } from "react"
import {
  InstantSearch,
  // Configure,
  connectSearchBox,
} from "react-instantsearch-dom"
import SearchBox from "./searchbox2"
import SearchResult from "./search-result"
import SearchFilters from "./filters"
import TranslitButton from "../search/translitButton"

const VirtualSearchBox = connectSearchBox(() => null)

export default function Search({ indices, suggestIndex, searchString, type }) {
  const urlParams = new URLSearchParams(searchString)
  const rootRef = createRef()
  // Temp hack to handle clear button
  const query =
    !urlParams.has("query") || urlParams.get("query") === "null"
      ? ""
      : urlParams.get("query")
  const [hasFocus, setFocus] = useState(false)
  const [showAdvanced, setShowAdvanced] = useState(type ? true : false)

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )
  
  return (
    <div ref={rootRef}>
      <Grid container style={{ marginBottom: 40 }}>
        <Grid item md={9} xs={12}>
          <InstantSearch searchClient={searchClient} indexName={suggestIndex}>
            <SearchBox
              onFocus={() => setFocus(true)}
              hasFocus={hasFocus}
              query={query}
              urlParams={urlParams}
            />
          </InstantSearch>
        </Grid>
        <Grid item md={3} xs={12} style={{ textAlign: "right" }}>
          <Box
            display="flex"
            justifyContent={{ xs: "space-between", md: "end" }}
          >
            <Hidden mdUp>
              <TranslitButton />
            </Hidden>
            <FormControlLabel
              control={
                <Switch
                  checked={showAdvanced}
                  onChange={() => setShowAdvanced(!showAdvanced)}
                  name="checkedA"
                  color="primary"
                />
              }
              label="Advanced filters"
              labelPlacement="start"
            />
          </Box>
        </Grid>
      </Grid>
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0].name}
        // onSearchStateChange={({ query }) => setQuery(query)}
      >
        {/* <Configure hitsPerPage={10} /> */}
        <Box display="flex" flexWrap={{ xs: "wrap-reverse", md: "nowrap" }}>
          <Box
            width={{ xs: 1, md: showAdvanced ? 3 / 4 : 1 }}
            pr={{ xs: 0, md: 1 }}
          >
            <VirtualSearchBox defaultRefinement={query} />
            <SearchResult
              show={true}
              indices={indices}
              urlParams={urlParams}
              query={query}
            />
          </Box>
          <Grow direction="left" in={showAdvanced} mountOnEnter unmountOnExit>
            <Box width={{ xs: 1, md: 1 / 4 }} pl={{ xs: 0, md: 1 }} pb={5}>
              <SearchFilters searchString={searchString} type={type} />
            </Box>
          </Grow>
        </Box>
      </InstantSearch>
    </div>
  )
}
